$padding: 20px;
$menu-height: 80px;
$bg-color: #fafafa;
$grey: #ddd;
$black: #000;

*,
*::before,
*::after {
  box-sizing: border-box;
}

.browserupgrade {
  margin: 0.2em 0;
  background: $grey;
  color: $black;
  padding: 0.2em 0;
}
html,
body {
  width: 100%;
}

body {
  margin: 0;
  background: $bg-color;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 22px;
}

h1,
h2 {
  font-size: 4rem;
  margin: 0 0 0.5em;
  letter-spacing: -0.05em;
  @media screen and(max-width: 500px) {
    font-size: 2.1em;
  }
}

h3 {
  margin: 0 0 0.5em;
  font-size: 1.75rem;
  font-weight: 400;
}

h4 {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
  font-style: italic;
}

p {
  margin: 0 0 0.5em;
  line-height: 1.4;
}

ul {
  margin: 0 0 0.5em;
  &.num {
    list-style-type: decimal;
  }
}

a {
  color: $black;
}

address {
  font-style: normal;
}

sup {
  vertical-align: text-top;
  padding: 0 0 0 0.15rem;
}

.eurostile {
  font-family: eurostile-extended, sans-serif;
  font-weight: 900;
  font-style: normal;
  line-height: 1.3;
}
.text-center {
  text-align: center;
}

//––––––––––––––––––––––––––––––––––––––––
//HEADER
//––––––––––––––––––––––––––––––––––––––––
header {
  padding: 0 $padding;
  @media screen and(max-width: 500px) {
    padding: 0 12px;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $menu-height;
  background: $bg-color;
  z-index: 100;
  border-bottom: solid 1px $grey;
  .header-inner {
    width: 100%;
    display: flex;
    position: relative;
    //Logo
    .logo-wrapper {
      flex: 1;
      margin: 0.25em 0;
      a {
        text-decoration: none;
        h1 {
          margin: -0.75rem 0 0;
          font-size: 4rem;
          @media screen and(max-width: 500px) {
            margin: 0;
            font-size: 2.1em;
          }
        }
      }
    }
    //Navigation
    nav {
      padding: 0.1em 0 0;
      ul {
        display: flex;
        padding: $padding;
        margin: 0;
        li {
          list-style-type: none;
          margin: 0 0 0 2em;
          a {
            text-decoration: none;
          }
        }
      }
      //mobile nav
      @media screen and (max-width: 1024px) {
        visibility: hidden;
        ul {
          background: $bg-color;
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          flex-direction: column;
          padding-top: 30vw;
          li {
            margin: 0 0 0.5em;
          }
        }
      }
    }
    nav.active {
      visibility: visible;
    }
    #toggleNav {
      display: none;
      cursor: pointer;
      z-index: 10;
      @media screen and (max-width: 1024px) {
        display: block;
      }
      margin-top: 22px;
    }
  }
}

//––––––––––––––––––––––––––––––––––––––––
//CONTENT
//––––––––––––––––––––––––––––––––––––––––
main {
  margin-top: $menu-height;
  .row {
    border-bottom: solid 1px $grey;
    .row-inner {
      padding: 1rem $padding 1rem;
      @media screen and(max-width: 500px) {
        padding: 1rem 12px 1rem;
      }
      &.align-center {
        max-width: 1200px;
        margin: 0 auto;
      }
    }
  }
  ul {
    padding: 0;
    li {
      list-style-position: inside;
    }
    &.grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
      grid-column-gap: 1.5rem;
      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      }
    }
  }
  .two-cols {
    display: flex;
    .col-1 {
      flex: 1;
      margin-right: 0.75rem;
    }
    .col-2 {
      flex: 1;
      margin-left: 0.75rem;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      .col-1,
      .col-2 {
        margin: 0 0 0.75rem;
      }
    }
  }
}

img {
  width: 100%;
  height: auto;
}

//––––––––––––––––––––––––––––––––––––––––
//PRODUCTS COVER
//––––––––––––––––––––––––––––––––––––––––
main.product {
  .cover {
    width: 100%;
    height: 80vh;
    position: relative;
    .cover-inner {
      padding: $padding;
      h2,
      p {
        position: relative;
        background: $bg-color;
        clear: both;
        float: left;
        margin: 0.25em 0;
      }
    }
    canvas {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -10;
    }
  }
}

//––––––––––––––––––––––––––––––––––––––––
//COMPONENTS
//––––––––––––––––––––––––––––––––––––––––

// 1. buttons
//1.1 black border
a.button {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 1rem auto;
  padding: 1rem 1rem 1.2rem;
  border: solid 1px $black;
  border-radius: 2px;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    background: $black;
    color: $grey;
  }
}
//1.2 switch
label.switch {
  text-align: left;
  width: 120px;
  height: 60px;
  border-radius: 50px;
  border: solid 1px $black;
  display: inline-block;
  position: relative;
  cursor: pointer;
}
label.switch > span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50px;
}
label.switch > input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}
label.switch > span:after {
  content: "off";
  padding: 10px 0 0;
  text-align: center;
  color: $grey;
  cursor: pointer;
  position: absolute;
  top: 50%;
  z-index: 3;
  transition: transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
  width: 50px;
  height: 50px;
  transform: translate(0, -25px);
  background-color: $black;
  margin-left: 4px;
  border-radius: 100%;
}
label.switch.on > span:after {
  content: "on";
}
label.switch > input[type="checkbox"]:checked ~ span:after {
  transform: translate(60px, -25px);
}

// 2. slider
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  width: 100%;
  background: $grey;
  margin: 1.5rem 0 1.5rem;
  &:focus {
    outline: 0;
  }
  @media screen and (max-width: 768px) {
    width: calc(100%-2rem);
    margin: 1.5rem 1rem 1.5rem;
  }
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background: $black;
  border-radius: 50%;
}
input[type="range"]::-moz-range-thumb {
  width: 22px;
  height: 22px;
  background: $black;
  border-radius: 50%;
}
.range-dida {
  display: flex;
  margin: 0 0 1.5rem;
  span {
    flex: 1;
    text-align: center;
  }
  span:first-child {
    text-align: left;
  }
  span:last-child {
    text-align: right;
  }
}

// 3. colors
li.color {
  list-style-type: none;
  div {
    display: flex;
    height: 160px;
    div {
      height: 100%;
      width: 50%;
    }
  }
  p {
    margin: 0.4rem 0 1.8rem;
  }
}

// 4. sizes
li.size {
  list-style-type: none;
  div {
    height: 3px;
    background: $black;
  }
  p {
    margin: 0.4rem 0 1.8rem;
  }
}

// 5. img levels
.img-levels {
  position: relative;
  img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
  }
  img:first-child {
    position: relative;
  }
}

//6. map
.map {
  width: 100%;
  height: 80vh;
  @media (hover: none) and (pointer: coarse) {
    height: 55vh;
  }
  h3 {
    font-size: 1rem;
  }
  .mapboxgl-popup {
    min-width: 160px;
  }
  .mapboxgl-popup-content {
    padding: 0.5rem 1rem;
  }
}

.marker {
  width: 35px;
  height: 35px;
  padding: 0 0 0.15rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: eurostile-extended, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 1.25rem;
  color: $bg-color;
}

//––––––––––––––––––––––––––––––––––––––––
//TRANSITIONS
//––––––––––––––––––––––––––––––––––––––––

//1. in/out

//2. hover

.hover-resize {
  position: relative;
  width: 100%;
  height: 42vw;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    height: 90vw;
  }
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: width 0.5s, height 0.5s, top 0.5s, left 0.5s;
  }
  &:hover img {
    width: 105%;
    height: 105%;
    top: -2.5%;
    left: -2.5%;
  }
}
.hover-dark {
  img {
    filter: brightness(1);
    transition: 0.5s;
  }
  &:hover {
    img {
      filter: brightness(0.3);
    }
  }
}

.hover-blur {
  img {
    filter: blur(0);
  }
  &:hover {
    img {
      filter: blur(40px);
    }
  }
}
